import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import Axios from 'axios';

import '../assets/css/pages/login.scss'

export default () => {
    const history = useHistory()

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [showError, setShowError] = useState(false)
    const [error, setError] = useState()

    const login = async event => {
        event.preventDefault();
        try {
            const response = await Axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/user/authenticate`, { 'name': email, 'password': password })
            if (response.data.organisations[0].orgType !== 'ORGANISATION')
                setError('Please use a non admin account')
            else {
                localStorage.setItem('user', JSON.stringify(response.data));
                history.push("/customers")
            }
        } catch (e) {
            setShowError(true)
        }
    }

    const changePassword = (e) => {
        setPassword(e.target.value)
        setShowError(false)
    }

    const changeEmail = (email) => {
        setEmail(email)
        setShowError(false)
    }

    return (
        <div className='login-page'>
            <div className='login-form'>
                <img className='logo' src={require("../assets/images/full-icon.svg")} />
                <div className={`error-message-container ${!showError && 'error-message-container-hidden'}`}>
                    <label>Invalid username or password</label>
                </div>
                <form onSubmit={login} autocomplete='on'>
                    <input placeholder="Email address" type='email' name='login-email' autocomplete="section-email" onChange={e => changeEmail(e.target.value)} />
                    <input className='password-input' name='login-pass' autocomplete="section-password" type='password' placeholder='Password' onChange={e => changePassword(e)} />
                    {/* <a href='#/password/new'>Forgotten password?</a> */}
                    {error && <label className='error'>{error}</label>}
                    <input type='submit' value='Login' />
                </form>
            </div>
        </div>
    )
}