import React from 'react';
import HttpsRedirect from 'react-https-redirect';

import Routes from './routes/router'

export default () => {
  return (
    <HttpsRedirect>
        <Routes />
      </HttpsRedirect>
  );
}
