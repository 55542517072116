import React from 'react'

import '../../assets/css/components/popup/registration-complete-popup.scss'

export default ({ user }) => {

    return (
        <div className='registration-complete-popup'>
            <div className="registration-complete-popup-content">
                <div className='registration-complete-top'>
                    <img src={require('../../assets/images/done.svg')} />
                </div>
                <div className='registration-complete-bottom'>
                    <div className='registration-complete-bottom-top'>
                        <img className='logo' src={require("../../assets/images/full-icon.svg")} />
                        <label className='title'>{user.alreadyRegistered ? 'Welcome back' : 'Registration complete!'}</label>
                        {user.alreadyRegistered && <label className='title'> {user.forename} {user.surname}!</label>}                    </div>
                </div>
            </div>
        </div >
    )
}