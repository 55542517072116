import React from 'react'

import { HashRouter, Route, Switch, Redirect, useLocation } from "react-router-dom";

import Customers from './customers'
import Register from './register'
import RegistrationComplete from './registration-complete'

import '../assets/css/pages/main.scss'

export default () => {
    return (
        <div className='main'>
            <HashRouter>
                <Switch >
                    <Route path='/customers' >
                        <Customers />
                    </Route>
                    <Route path='/complete' >
                        <RegistrationComplete />
                    </Route>
                    <Route path='/register/:organisationId' >
                        <Register />
                    </Route>
                    <Route render={() => <Redirect to="/customers" />} />
                </Switch>
            </HashRouter>
        </div>
    )
}