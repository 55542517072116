import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import useInfiniteScroll from '@closeio/use-infinite-scroll';

import { ReactComponent as MenuIcon } from '../assets/images/menu-btn.svg';

import api from '../utils/api'
import Customer from '../components/customer'
import VenuesPopup from '../components/popup/venues-popup'

import '../assets/css/pages/customers.scss'

export default () => {

    const history = useHistory()

    const user = JSON.parse(localStorage.getItem('user'))
    const organisation = user.organisations[0]

    const [venues, setVenues] = useState([])
    const [selectedVenue, setSelectedVenue] = useState()
    const [customers, setCustomers] = useState([])
    const [hasMore, setHasMore] = useState(false);
    const [reset, setReset] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [total, setTotal] = useState()
    const [name, setName] = useState('')
    const [showPoup, setShowPopup] = useState(false)
    const [page, loaderRef, scrollerRef] = useInfiniteScroll({ hasMore, reset });

    useEffect(() => {
        const fetchData = async () => {
            const result = await api.get(`/organisations/${organisation.externalId}/network?relationship=VENUE&page=0size=20`)
            const venues = result.data.content
            setVenues(venues)
            setSelectedVenue(venues[0])
        }
        fetchData()
    }, [organisation.externalId])

    const getDate = () => {
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 21);
        currentDate.setMilliseconds(0)
        currentDate.setSeconds(0)
        currentDate.setMinutes(0)
        currentDate.setHours(0)
        return currentDate.getTime()
    }

    const fetchCustomers = async reset => {
        const result = await api.get(`/organisations/${selectedVenue.externalId}/contacts?type=&page=${page}&name=${name}&visitFrom=${getDate()}&sort=lastVisit,DESC&size=20`)

        if (reset)
            setCustomers([...result.data.content])
        else
            setCustomers([...customers, ...result.data.content])
        const pageable = result.data.pageable
        setHasMore(((pageable.page + 1) * pageable.size) < result.data.total)
        setTotal(result.data.total)
        setReset(false)
    }

    const filter = (e) => {
        if (e.target.value.length > 2)
            setName(e.target.value)
        else
            setName("")
    }

    useEffect(() => {
        if (selectedVenue)
            fetchCustomers(false)
    }, [page])

    useEffect(() => {
        if (selectedVenue) {
            setReset(true)
            fetchCustomers(true)
        }
    }, [selectedVenue, name])


    const logOut = () => {
        localStorage.removeItem('user')
        window.location.reload();
    }

    const onChangeVenue = () => {
        setShowMenu(false)
        setShowPopup(true)
    }

    const closePopup = () => {
        setShowPopup(false)
    }

    return (
        <div className='customers-page'>
            <div className='top'>
                <div className='logo-menu'>
                    <img className='logo' src={require("../assets/images/full-icon.svg")} />
                    <MenuIcon className={`menu-btn ${showMenu && 'menu-btn-selected'}`} onClick={() => setShowMenu(!showMenu)} />
                </div>
                {showMenu &&
                    <div className='menu'>
                        {venues && venues.length > 2 && <label onClick={onChangeVenue}>Change Venue</label>}
                        <label onClick={logOut}>Log out</label>
                    </div>
                }
                <label className='title'>People Currently at Venue <span>(</span><span>{total}</span><span>)</span></label>
                <input type='text' placeholder='Search contact...' className='search' onChange={e => filter(e)} />
                {/* {venues && venues.length > 2 && <Dropdown options={venues} selected={selectedVenue} onSelect={setSelectedVenue} />} */}
            </div>
            <div className='customers-container' ref={scrollerRef}>
                {customers.map(customer => <Customer key={customer.eternalId} customer={customer} />)}
                {hasMore && <div ref={loaderRef}></div>}
            </div>
            <div className='bottom'>
                <div className='add-btn' onClick={() => history.push(`/register/${selectedVenue.externalId}`)}>
                    <img src={require('../assets/images/add.svg')} />
                    <label>Add New</label>
                </div>
                <label className='privacy'>Click here to read our Privacy Policy</label>
            </div>
            {showPoup && <VenuesPopup venues={venues} selectedVenue={selectedVenue} setSelectedVenue={setSelectedVenue} close={closePopup} />}
        </div>
    )
}