import React from 'react'

import { timeDifference } from '../utils/common'

import '../assets/css/components/customer.scss'

export default ({ customer }) => {

    const getAvatar = (customer) => {
        if (customer.avatarUrl) {
            if (customer.avatarUrl.includes('facebook'))
                return `${customer.avatarUrl}?width=300&height=300`
            else
                return customer.avatarUrl
        }
        return null
    }

    return (
        <div className='customer'>
            <img src={getAvatar(customer) || require('../assets/images/user.svg')} />
            <div className='names'>
                <label className='name'>{customer.forename} {customer.surname}</label>
                <hr />
                <label className='last-visit'>{customer.lastVisit ? timeDifference(new Date(), new Date(customer.lastVisit * 1000)) : 'never'}</label>
            </div>
        </div>
    )
}