import React from 'react'

import {
    BrowserRouter as Router,
    Route,
    Redirect
} from "react-router-dom";

export const PrivateRoute = ({ route, Component }) => {
    if (JSON.parse(localStorage.getItem('user')) !== null) {
        return <Route path={route}>
            <Component />
        </Route>

    }
    return <Redirect to="/login" />

}