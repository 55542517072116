import React from 'react'
import { useHistory, useLocation } from "react-router-dom";

import '../assets/css/pages/registration-complete.scss'

export default () => {
    const history = useHistory()
    const location = useLocation()
    const user = location.state ? location.state.user : {}

    return (
        <div className='registration-complete'>
            <div className='registration-complete-box'>
                <div className='registration-complete-top'>
                    <img src={require('../assets/images/done.svg')} />
                </div>
                <div className='registration-complete-bottom'>
                    <div className='registration-complete-bottom-top'>
                        <img className='logo' src={require("../assets/images/full-icon.svg")} />
                        <label className='title'>{user.alreadyRegistered ? 'Welcome back' : 'Registration complete!'}</label>
                        {user.alreadyRegistered && <label className='title'> {user.forename} {user.surname}!</label>}
                    </div>

                    <button onClick={() => history.push('/customers')}>Homepage</button>
                    <label className='privacy'>Click here to read our <a target="_blank" href='https://unaro.co.uk/privacy-policy'>Privacy Policy</a></label>
                </div>
            </div>
        </div>
    )
}