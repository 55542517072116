import React from 'react'

import '../../assets/css/components/popup/venues-popup.scss'

export default ({ venues, selectedVenue, setSelectedVenue, close }) => {

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    const changeVenue = venue => {
        setSelectedVenue(venue)
        close()
    }

    return (
        <div className='venues-popup' onClick={onClick}>
            <div className="venues-popup-content">
                {venues.map(venue =>
                    <div key={venue.externalId} className='venue-item' style={{ backgroundColor: venue.externalId === selectedVenue.externalId ? 'grey' : 'white', color: venue.externalId === selectedVenue.externalId ? 'white' : 'black' }} onClick={() => changeVenue(venue)}>
                        <label>{venue.name}</label>
                    </div>
                )}
            </div>
        </div >
    )
}