import React from 'react'

import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import { PrivateRoute } from './privateRoute'
import Main from '../pages/main'
import Login from '../pages/login'
import Register from '../pages/register'

export default () => {
    return (
        <HashRouter>
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/qr/register/:landingPageId">
                    <Register />
                </Route>
                <PrivateRoute path="/" Component={Main} />
                <Route render={() => <Redirect to="/customers" />} />
            </Switch>
        </HashRouter>
    )
}