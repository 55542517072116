import React, { useState, useEffect, useRef } from 'react'
import api from '../utils/api'
import axios from 'axios'
import { useParams, useHistory } from "react-router-dom";
import TitleDropdown from '../components/widgets/title-dropdown'
import RegistrationCompletePopup from '../components/popup/registration-complete-popup'
import Loader from 'react-loader-spinner'
import { hexToRGB } from '../utils/common'

import '../assets/css/pages/register.scss'

export default () => {
    const history = useHistory()
    const { organisationId } = useParams();
    const { landingPageId } = useParams();

    const titles = ['Mr.', 'Mrs.', 'Miss']
    const genders = ['male', 'female']

    const [showRegistrationCompletePopup, setShowRegistrationCompletePopup] = useState()

    const [logoUrl, setLogoUrl] = useState('')
    const [backgroundUrl, setBackgroundUrl] = useState()
    const [customer, setCustomer] = useState({ title: titles[0], gender: genders[0], registrationType: 'EMAIL' })
    const [errors, setErrors] = useState([])
    const [showError, setShowError] = useState(false)
    const [emailError, setEmailError] = useState()
    const [landingPage, setLandingPage] = useState({})
    const [settings, setSettings] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const calendarRef = useRef()

    const getUrl = (type) => {
        const creative = landingPage.creatives.find(creative => creative.type === type)
        return creative ? creative.url : null
    }

    const fetchLandingPageForId = async landingPageId => {
        try {
            setIsLoading(true)
            const { data: landingPage } = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/landingPages/${landingPageId}`)
            setLandingPage(landingPage)
            setSettings(landingPage.landingpageSettings)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    const fetchLandingPage = async () => {
        const { data: landingPages } = await api.get(`/organisations/${organisationId}/landingPages?track=true`)
        if (landingPages.length > 0) {
            await fetchLandingPageForId(landingPages[0].externalId)
        }
    }

    useEffect(() => {
        if (landingPage && landingPage.creatives) {
            setLogoUrl(getUrl('LOGO'))
            setBackgroundUrl(getUrl('BACKGROUND'))
        }
    }, [landingPage.externalId])

    useEffect(() => {
        if (organisationId)
            fetchLandingPage()
    }, [organisationId])

    useEffect(() => {
        if (landingPageId)
            fetchLandingPageForId(landingPageId)
    }, [landingPageId])

    const changeEmail = (value) => {
        setErrors(errors.filter(error => error !== 'email'))
        setShowError(false)
        setCustomer({ ...customer, email: value })
    }

    const changeForename = (value) => {
        setShowError(false)
        setErrors(errors.filter(error => error !== 'forename'))
        setCustomer({ ...customer, forename: capitalize(value) })
    }

    const changeSurname = (value) => {
        setShowError(false)
        setErrors(errors.filter(error => error !== 'surname'))
        setCustomer({ ...customer, surname: capitalize(value) })
    }

    const changePhone = (value) => {
        setShowError(false)
        setErrors(errors.filter(error => error !== 'phone'))
        setCustomer({ ...customer, phones: [{ phoneNumber: value }] })
    }

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const isValidEmail = email => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const register = async (onDone) => {
        var e = []
        if (!customer.email || customer.email.length < 3)
            e = [...e, 'email']
        if (!customer.forename || customer.forename.length < 3)
            e = [...e, 'forename']
        if (!customer.surname || customer.surname.length < 3)
            e = [...e, 'surname']
        if (!customer.phones || customer.phones.length === 0 || !customer.phones[0].phoneNumber || customer.phones[0].phoneNumber.length < 3 || !customer.phones[0].phoneNumber.match(/\d/))
            e = [...e, 'phone']

        setErrors(e)

        if (e.length > 0)
            setShowError(true)

        if (e.length === 0) {
            if (!isValidEmail(customer.email))
                setEmailError('Invalid email')
            else {
                setErrors([])
                customer.contactType = "PERSON"
                customer.organisationId = organisationId ? organisationId : landingPage.organisationId
                customer.trackAndTrace = true

                try {
                    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/contact/register`, customer, { headers: { 'api_key': 'pk_live_FGHJYYTALAek2rwZ0ZHbrTDj00skByotel' } })
                    onDone(data)
                } catch (e) {
                    if (e.response && e.response.data && e.response.data.errorDescription)
                        setEmailError(e.response.data.errorDescription)
                    else
                        setEmailError('Registration error')
                }
            }
        }
    }

    const onRegister = async () => {
        await register(response => {
            if (landingPageId) {
                setCustomer({ title: titles[0], gender: genders[0], registrationType: 'EMAIL' })
                setShowRegistrationCompletePopup({ alreadyRegistered: false })
                setTimeout(() => setShowRegistrationCompletePopup(null), response.alreadyRegistered ? 2000 : 1000)
            }
            else
                history.push({ pathname: '/complete', state: { user: response } })
        })

    }

    const onAddMore = async () => {
        await register(response => {
            setCustomer({ title: titles[0], gender: genders[0], registrationType: 'EMAIL' })
            setShowRegistrationCompletePopup(response)
            setTimeout(() => setShowRegistrationCompletePopup(null), response.alreadyRegistered ? 2000 : 1000)
        })
    }

    return (
        <div className='register-page' style={{ backgroundImage: backgroundUrl ? `url(${backgroundUrl}` : 'unset' }}>
            <style jsx>{`
            input::placeholder {
                color: ${hexToRGB(landingPage.inputColor, 0.6)};
            }
            `}</style>
            {landingPageId && !landingPage.externalId ?
                isLoading ?
                    <div className='landing-page-nf'>

                        <Loader
                            type="Oval"
                            color="#FFFFFF"
                            height={50}
                            width={50}
                        />
                    </div>
                    :
                    <div className='landing-page-nf'>
                        Landing page not found
                </div>
                :
                <div className='register-form' style={{ backgroundColor: landingPage.boxColor ? hexToRGB(landingPage.boxColor, landingPage.menuOpacity ? 1 - landingPage.menuOpacity : 1) : '#ffffff', border: landingPage.boxBorderVisibility === 'SHOW' ? `1px solid ${landingPage.boxBorderColor}` : 'none' }}>
                    <img className='logo' src={logoUrl ? logoUrl : require("../assets/images/full-icon.svg")} />
                    <label className='title' style={{ color: landingPage.textColor }}>Connect with email</label>
                    <input className='input-preview' style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} placeholder="Forename:" value={customer.forename || ''} className={`${errors.length > 0 && errors.includes('forename') && 'input-error'}`} onChange={e => changeForename(e.target.value)} />
                    <input className='input-preview' style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} placeholder="Forename:" value={customer.forename || ''} placeholder="Surname:" value={customer.surname || ''} className={`${errors.length > 0 && errors.includes('surname') && 'input-error'}`} onChange={e => changeSurname(e.target.value)} />
                    <input className='input-preview' style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} placeholder="Forename:" value={customer.forename || ''} type='number' placeholder="Phone number:" value={customer.phones ? customer.phones[0].phoneNumber : ''} className={`${errors.length > 0 && errors.includes('phone') && 'input-error'}`} onChange={e => changePhone(e.target.value)} />
                    <input className='input-preview' style={{ color: landingPage.inputColor, borderColor: landingPage.inputColor }} placeholder="Forename:" value={customer.forename || ''} type='email' autoFocus placeholder="Email:" value={customer.email || ''} className={`${errors.length > 0 && errors.includes('email') && 'input-error'}`} onChange={e => changeEmail(e.target.value)} />
                    {settings.requireDob &&
                        <div className='birthday-input' onClick={() => calendarRef.current.focus()} style={{ borderColor: landingPage.inputColor }}>
                            <label style={{ color: landingPage.inputColor }}>Date of birth:</label>
                            <input style={{ color: landingPage.inputColor }} placeholder="Forename:" value={customer.forename || ''} className='input-preview' type='date' ref={calendarRef} value={customer.birthday ? new Date(customer.birthday).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)} onChange={e => setCustomer({ ...customer, birthday: new Date(e.target.value).getTime() })} />
                        </div>
                    }
                    <TitleDropdown color={landingPage.inputColor} title='Title:' options={titles} selected={customer.title || titles[0]} onSelect={value => setCustomer({ ...customer, title: value })} />
                    <TitleDropdown color={landingPage.inputColor} title='Gender:' options={genders} selected={customer.gender || genders[0]} onSelect={value => setCustomer({ ...customer, gender: value })} />
                    {showError && <label style={{ color: landingPage.errorTextColor }} className='error-label'>Please fill all fields</label>}
                    {emailError && <label style={{ color: landingPage.errorTextColor }} className='error-label'>{emailError}</label>}

                    <div className='register-btn' style={{ backgroundColor: landingPage.buttonColor }} onClick={onRegister}>
                        <label>Register</label>
                        <img src={require('../assets/images/arrow.svg')} />
                    </div>
                    <div className='register-btn add-more' style={{ backgroundColor: landingPage.goButtonColor }} onClick={onAddMore}>
                        <img src={require('../assets/images/add.svg')} />
                        <label>Add More Guests</label>
                    </div>
                    <label className='privacy'>Click here to read our <a style={{ color: landingPage.linkColor, textDecoration: 'none' }} target="_blank" href='https://unaro.co.uk/privacy-policy'>Privacy Policy</a></label>
                </div>
            }
            {showRegistrationCompletePopup && <RegistrationCompletePopup user={showRegistrationCompletePopup} />}
        </div>
    )
}