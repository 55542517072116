import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    timeout: 10000,
    params: {}
});

instance.interceptors.request.use(async function (config) {
    try {
        config.headers.Authorization = 'Bearer ' + await getAccessToken()
    } catch (e) {
        localStorage.removeItem('user')
        localStorage.removeItem('current-organisation')
        localStorage.removeItem('sidebar-locked')
        localStorage.removeItem('selected-organisation')
        localStorage.removeItem('l-filter')
        localStorage.removeItem('r-filter')
        localStorage.removeItem('current-customers-page')
        window.location.reload()
    }
    return config;
}, function (error) {
    return Promise.reject(error)
})

const getAccessToken = async () => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.exp - 10000 < new Date().getTime()) {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/user/refresh?refresh_token=${user.refreshToken}`, {})
        localStorage.setItem('user', JSON.stringify(response.data));
        return response.data.accessToken
    } else
        return user.accessToken
}

export const cloudinaryApi = axios.create({
    baseURL: `https://api.cloudinary.com/v1_1`,
    timeout: 20000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'multipart/form-data'
    }
})

export default instance